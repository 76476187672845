.detail_body{
    width: 100%;
    padding-top: 100px;
    background: linear-gradient(225deg, #F5F7FC 0%, #F5F7FC 100%);
    padding-bottom: 30px;
    min-height: 85vh;
    .container{
        max-width: 1100px;
         min-height: 70vh;
        // background-color: red;
        margin: 0 auto;
        padding: 0 50px;
        .job_header{
            max-width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            background: #D93025;  
            color: white;
            font-weight: 500;
            font-size: 30px;
            
           }
        .detailElement_body{
            display: flex;
            width: 100%;
            padding-top: 70px;
            gap: 10px;
            .detailElement_bodyLeft{
                width: 20%;
                text-align: center;
                display: flex;
                flex-direction: column;
                align-items: center;
                button{
                    width: 150px;
                    padding: 10px 0;
                    border-radius: 8px;
                    background: #D93025;
                    color: white;
                    border: none;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                    font-size: 16px;
                    &:hover{
                        background: #e66157;
                    }
          
                  }
            }
            .detailElement_bodyRight{
                width: 80%;
              
            p{
                padding-top: 10px;
            }
               }
               
                }
            .image_b0dy{
                width: 100% !important;
                display: flex;
                align-items: center;
                justify-content: center;
                div{
                    width: 33.3%;
                    height: 200px;
                }
                img{
                    
                    width: 90%;
                    height: 100%;
                    border-radius: 8px;
                
                }
          
            }
            .data_name{
                font-size: 22px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                color: var(--color-two, #202124);
                text-align: center;
            }
            .data_compName{
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                color: var(--color-four, #34A853);
                display: flex;
                align-items: center;
                gap: 5px;
                padding-bottom: 7px;
            }
            .data_location{
                color: var(--text-color, #696969);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;
                gap: 5px;
                padding-bottom: 7px;
            }
            .data_date{
                color: var(--text-color, #696969);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;
                gap: 5px;
                padding-bottom: 20px;
            }
        }
    }
    .tab_pane1{
        display: flex;
        justify-content: space-between;
        
    }

form {
            // display: flex;
            .cancelBtn{
                margin-top: 20px;
                width: 150px;
                height: 40px;
                border-radius: 5px;
                border: none;
                color: white;
                font-size: 15px;
                font-weight: 500;
                transition: all 0.2s ease;
                background-color: #0067F1;
                &:hover {
                    background-color: #468ce9;
                    cursor: pointer;
                }
            } 
            .addBtn{
                margin-top: 20px;
                width: 150px;
                height: 40px;
                border-radius: 5px;
                border: none;
                background-color: #5aac44;
                color: white;
                font-size: 15px;
                font-weight: 500;
                transition: all 0.2s ease;
                margin-left: 20px;
                &:hover {
                    background-color: #72b75f;
                    cursor: pointer;
                }

            }

        }

        .inp_name_body {
            padding-top: 10px;
            height: 84px;
            margin-bottom: 20px;
            .require{
                color: red;
                font-size: 13px;
                margin-top: 4px;
            }
            .exam_name {
                // color: #253858;
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 5px;

            }

            .inp_body {
                width: 100%;
                height: 55px;
                border: 1px solid rgb(230, 230, 230);
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 5px;
                gap: 8px;
                font-size: 20px;
                input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

                input {
                    width: 100%;
                    height: 35px;
                    border: none;
                    font-size: 18px;
                    background-color: transparent;
                    &::placeholder {
                        font-size: 16px;
                        font-style: italic;
                        color: #264e93;

                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        .textArea_name_body {
            margin-top: 15px;
            height: 220px;
            margin-bottom: 20px;
            width: 98%;
            .require{
                color: red;
                font-size: 13px;
                margin-top: 4px;
            }
            .exam_name {
                // color: #253858;
                font-weight: 500;
                font-size: 18px;
                margin-bottom: 5px;
                // margin-top: 20px;

            }

            .inp_body {
                width: 100%;
                height: 200px;
                border: 1px solid rgb(230, 230, 230);
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0px 5px;
                gap: 8px;
                font-size: 20px;
                padding: 10px 7px;
                

                textarea {
                    width: 100%;
                    height: 100%;
                    border: none;
                    font-size: 18px;
                    background-color: transparent;
                    &::placeholder {
                        font-size: 16px;
                        font-style: italic;
                        color: #264e93;

                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }
        .about_userBody{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        .inp_name_body{width: 98%;}
        }
    .sumbitBtnBody{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
.formSumbit{
    width: 120px;
    height: 40px;
    border-radius: 8px;
    background: var(--color-one, #1967D2);
    color:white;
    border: none;
    font-size: 18px;
    margin-top: 30px;
    cursor: pointer;
}
.formSumbitClose{
    width: 120px;
    height: 40px;
    border-radius: 8px;
    background: var(--color-one, #aa0f0f);
    color:white;
    border: none;
    font-size: 18px;
    margin-top: 30px;
    cursor: pointer;
}
.modal{
    margin-top: 120px;
}

@media screen and (max-width: 750px) {
    .container{
        padding: 0 !important;
    }
   .detailElement_body{
    flex-direction: column;
    align-items: center;
   }
   .tab_pane1{
    flex-direction: column;
}
.about_userBody{
    flex-direction: column;
}
.inp_name_body{width: 100% !important;}
.textArea_name_body{
    width: 100% !important;
}
.image_b0dy{
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    div{
        width: 100% !important;
        height: 200px;
        margin-top: 5px;
    }
    img{
        
        width: 100% !important;
        height: 100%;
        border-radius: 8px;
    
    }

}
  }