.contact_section {
    width: 100%;
    // background-color: #f2fbfd;
    background: linear-gradient(225deg, #F5F7FC 0%, #F5F7FC 100%);
    .container {
      width: 1200px;
      margin: 0 auto;
      .contact_body {
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 50px;
        form {
          // display: flex;
          width: 100%;
          .cancelBtn{
              margin-top: 20px;
              width: 150px;
              height: 40px;
              border-radius: 5px;
              border: none;
              color: white;
              font-size: 15px;
              font-weight: 500;
              transition: all 0.2s ease;
              background-color: #0067F1;
              &:hover {
                  background-color: #468ce9;
                  cursor: pointer;
              }
          } 
          .addBtn{
              margin-top: 20px;
              width: 150px;
              height: 40px;
              border-radius: 5px;
              border: none;
              background-color: #5aac44;
              color: white;
              font-size: 15px;
              font-weight: 500;
              transition: all 0.2s ease;
              margin-left: 20px;
              &:hover {
                  background-color: #72b75f;
                  cursor: pointer;
              }
  
          }
  
      }
  
      .inp_name_body {
          padding-top: 10px;
          height: 84px;
           margin-bottom: 15px;
          .require{
              color: red;
              font-size: 13px;
              margin-top: 4px;
          }
          .exam_name {
              // color: #253858;
              font-weight: 500;
              font-size: 18px;
              margin-bottom: 5px;
  
          }
  
          .inp_body {
              width: 100%;
              height: 55px;
              border: 1px solid rgb(230, 230, 230);
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0px 5px;
              gap: 8px;
              font-size: 20px;
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }
            
            input[type=number] {
                -moz-appearance:textfield; /* Firefox */
            }

  
              input {
                  width: 100%;
                  height: 35px;
                  border: none;
                  font-size: 18px;
                  background-color: transparent;
                  &::placeholder {
                      font-size: 16px;
                      font-style: italic;
                      color: #264e93;
  
                  }
  
                  &:focus {
                      outline: none;
                  }
              }
          }
      }
      .textArea_name_body {
          padding-top: 10px;
          height: 220px;
          margin-bottom: 30px;
          width: 100%;
          .require{
              color: red;
              font-size: 13px;
              margin-top: 4px;
          }
          .exam_name {
              // color: #253858;
              font-weight: 500;
              font-size: 18px;
              margin-bottom: 5px;
  
          }
  
          .inp_body {
              width: 100%;
              height: 200px;
              border: 1px solid rgb(230, 230, 230);
              border-radius: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px 10px;
              gap: 8px;
              font-size: 20px;
              
  
              textarea {
                  width: 100%;
                  height: 100%;
                  border: none;
                  font-size: 18px;
                  background-color: transparent;
                  &::placeholder {
                      font-size: 16px;
                      font-style: italic;
                      color: #264e93;
  
                  }
  
                  &:focus {
                      outline: none;
                  }
              }
          }
      }
      .about_userBody{
          // display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
      .inp_name_body{width: 100%;}
      }
  .sumbitBtnBody{
      width: 100%;
      display: flex;
      align-items: center;
      // justify-content: center;
      gap: 10px;
      
  }
  .formSumbit{
  width: 120px;
  height: 40px;
  border-radius: 8px;
  background: var(--color-one, #1967D2);
  color:white;
  border: none;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  }
        .contact_body_left {
          width: 50%;
          h2{
              font-size: 40px;
              color: var(--color-two, #202124);
              margin-bottom: 15px;
              text-align: center;
              padding-top: 50px;
          }
          .footer_right {
            width: 600px;
            // height: 220px;
            padding: 30px 15px;
            /* From https://css.glass */
            background: rgba(60, 118, 219, 0.2);
            border-radius: 16px;
            box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            backdrop-filter: blur(5px);
            -webkit-backdrop-filter: blur(5px);
          //   border: 1px solid rgba(60, 118, 219, 0.18);
            h1 {
              color: var(--color-two, #202124);
              font-size: 25px;
               text-align: center;
              margin-bottom: 10px;
              
            }
            form {
              // display: flex;
              // // flex-wrap: wrap;
              // justify-content: space-between;
            }
            .inp_body {
              width: 100%;
              height: 40px;
              border: 1px solid #40b3e4;
              border-radius: 10px;
              display: flex;
              align-items: center;
              gap: 7px;
              padding: 0px 3px;
              color: #ffffff;
              margin-bottom: 15px;
              font-size: 16px;
  
              input {
                width: 100%;
                height: 100%;
                background-color: transparent;
                border: none;
                font-size: 16px;
                color: rgb(5, 5, 5);
                &:focus {
                  outline: none;
                }
                &::placeholder {
                  color: rgba(71, 71, 71, 0.493);
                  font-style: italic;
                }
              }
            }
            .textArea_body {
              margin-top: 10px;
              width: 100%;
              height: 70px;
              border: 1px solid #40b3e4;
              border-radius: 10px;
              display: flex;
              align-items: center;
              gap: 5px;
              padding: 0px 3px;
              color: white;
              outline: none;
              textarea {
                width: 100%;
                height: 100%;
                background-color: transparent;
                border: none;
                resize: none;
                color: black;
                padding: 5px 5px;
                font-size: 16px;
                &:focus {
                  outline: none;
                }
                &::placeholder {
                  color: rgba(71, 71, 71, 0.493);
                  font-style: italic;
                }
              }
            }
            .submit_btn {
              width: 100%;
              margin-top: 10px;
              input[type="submit"] {
                width: 120px;
                height: 40px;
                font-size: 16px;
                border-radius: 5px;
                border: none;
                background-color: #06B7D8;
                color: white;
                transition: all 0.3s ease;
                &:hover{
                  cursor: pointer;
                  background-color: #27AAE1;
                }
              }
            }
          }
        }
        .contact_body_right {
          width: 50%;
          height: 100%;
        }
      }
    }
  }
  .whatsapp{
    position: fixed;
    bottom: 4%;
    right: calc(100% - 98%);
    z-index: 888;
    color: #34A853;
    color: #53dc87;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    animation-name: pulse;
    padding: 10px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-duration: 1.5s;
    &:hover{
      bottom: 6%;
    }
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
    }
    80% {
      box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
    }
  }
  @media screen and (max-width: 1200px) {
      .container {
        width: 690px !important;
        margin: 0 auto;
        .contact_body{
          // flex-direction: column;
          
      }
  }
  .footer_right{
      width: 100% !important;
      h1{
          font-size: 22px !important;
      }
    
      
  }
  .contact_body_left{
          width: 100% !important;
          // order: 2;
          h2{
              font-size: 30px !important;
          }
      }
      .contact_body_right{
          // order: 1;
          margin-top: 100px;
          display: flex;
          height: 100%;
          align-items: center;
          width: 100% !important;
      }
    }
    @media screen and (max-width: 750px) {
      .container {
          width: 350px !important;
          margin: 0 auto;
          .contact_body{
            flex-direction: column;
            
        }
    }
    .footer_right{
        width: 100% !important;
        h1{
          font-size: 20px !important;
        }
    }
    .contact_body_left{
            width: 100% !important;
            order: 2;
            margin-bottom: 30px;
    
        }
        .contact_body_right{
          order: 1;
          display: flex;
            height: 100%;
            align-items: center;
            width: 100% !important;
        }
    }
    @media screen and (max-width: 350px) {
      .container {
          width: 300px !important;
          margin: 0 auto;
    }
    .footer_right{
        width: 100% !important;
        h1{
          font-size: 18px !important;
        }
    }
    .contact_body_left{
            width: 100% !important;
            order: 2;
            margin-bottom: 30px;
            h2{
              font-size: 25px !important;
            }
        }
        .contact_body_right{
          order: 1;
          display: flex;
            height: 100%;
            align-items: center;
            width: 100% !important;
        }
    }
  