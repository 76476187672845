// @import '~video-react/styles/scss/video-react'; 
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

.carusel_body_header {
    width: 100%;
    height: 80%;
    // div{
    // overflow: hidden;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // height: 20px;
    // }
    //  overflow: hidden;
    img{
      width:100%;
      height: 100% !important;
    }
    // span{
    //   width: 10px !important;
    //   height: 10px !important;
    // }
}
@media screen and (max-width: 750px) {
  .carusel_body_header{
    Video{
      width:100%;
      height: 500px !important;
    }
      img{
        width:100%;
        height: 500px !important;
      }
  }
}
 
//   }
//   @media screen and (max-width: 1200px) {
//     .carusel_contanier_header {
//       width: 690px !important;
//     }
//     .image_body{
//       height: 350px !important;
//       img{
//           height: 345px !important;
//       }
//     }
//     .carusel_text{
//       p{
//           width: 250px !important;
//           font-size: 17px;
//       }
//       h2{
//           font-size: 30px;
//       }
//     }
//   }
//   @media screen and (max-width: 750px) {
//     .carusel_contanier_header {
//       width: 350px !important;
//       flex-direction: column;
//     }
//     .carusel_text{
//       justify-content: center;
//       order: 2;
//       p{
//           width: 320px !important;
//           font-size: 15px;
//           text-align: center;
//       }
//       h2{
//           width: 320px !important;
  
//           font-size: 25px;
//       margin-bottom: 10px;
//      margin-left: 0px;
//       text-align: center;
  
//       }
//     }
//     .image_body{
//       order: 1;
//       width: 100% !important;
//       margin: 0 auto !important;
//       justify-content: center;
//     }
//   }
//   @media screen and (max-width: 350px) {
//     .carusel_contanier_header {
//       width: 300px !important;
//       flex-direction: column;
//     }
//     .carusel_text{
//       justify-content: center;
//       order: 2;
//       p{
//           width: 300px !important;
//           font-size: 15px;
//           text-align: center;
//       }
//       h2{
//           width: 300px !important;
  
//           font-size: 25px;
//       margin-bottom: 10px;
//      margin-left: 0px;
//       text-align: center;
  
//       }
//     }
//     .image_body{
//       order: 1;
//       width: 100% !important;
//       margin: 0 auto !important;
//       justify-content: center;
//     }
//   }
  