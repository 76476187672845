.container{
    max-width: 1200px;
    margin: 0 auto;
    .abouts1{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap:wrap;
      height: auto;
      width: 100%;
      div{
        width: 50%;
        padding: 50px 0;
        img{
          width: 100%;
          border-radius: 10px;
          height: 70%;

        }
      }
      p{
        width: 50%;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        span{
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
    .abouts2{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap:wrap;
      height: auto;
      width: 100%;
      div{
        width: 50%;
        padding-bottom: 50px;
        h1{
          text-align: center;
          font-size: 30px;
        
        }
      }
        img{
          width: 100%;
          border-radius: 10px;
          // height: auto;
          height: 100% !important;

      }
      p{
        // width: 50%;
        font-style: italic;
        font-size: 18px;
        font-weight: 500;
        padding: 0 10px;
        padding-bottom: 5px;

        span{
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
    .abouts3{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap:wrap;
      height: auto;
      width: 100%;
      padding: 60px 0;

      div{
        width: 50%;
        img{
          width: 100%;
          height: 100%;
          border-radius: 10px;
        }
      }
      p{
        width: 50%;
        text-align: center;
        font-size: 18px;
        font-weight: 500;
        padding: 0 10px;
        span{
          font-size: 30px;
          font-weight: 600;
        }
      }
    }
}
.whatsapp{
  position: fixed;
  bottom: 4%;
  right: calc(100% - 98%);
  z-index: 888;
  color: #34A853;
  color: #53dc87;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  animation-name: pulse;
  padding: 10px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-duration: 1.5s;
  &:hover{
    bottom: 6%;
  }
}
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}
.about_body{
    width: 100%;
    padding-top: 70px;
    .aboutTopBg{
        min-height: 210px;
        width: 100%;
        background-image: url('https://getwork.com/assets/img/hero-imgs/dark-blue-hero.svg');
        background-size: cover;
        background-position: center bottom;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        .topTextBody{
          width: 70%;
          padding: 0 10px;
          h2{
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 48px;
            font-weight: 900;           
            margin-bottom: 30.24px;
            margin-block-end: 30.24px;
            margin-block-start: 37.8px;
          }
          p{
            text-align: center;
            font-size: 20px;
            color: rgb(255, 255, 255);

          }
          h3{
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 24px;
            font-weight: 500;           
            margin-bottom: 30.24px;
            margin-block-end: 30.24px;
            margin-block-start: 37.8px;
          }
        }
    }
    .aboutTopBg2{
        min-height: 210px;
        width: 100%;
        background-image: url('https://getwork.com/assets/img/hero-imgs/dark-blue-hero.svg');
        background-size: cover;
        background-position: center bottom;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0;
        // margin-bottom: 3px;
        .topTextBody{
          width: 70%;
          padding: 0 10px;
          h2{
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 48px;
            font-weight: 900;           
            margin-bottom: 30.24px;
            margin-block-end: 30.24px;
            margin-block-start: 37.8px;
          }
          p{
            text-align: center;
            font-size: 20px;
            color: rgb(255, 255, 255);

          }
          h3{
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 24px;
            font-weight: 500;           
            margin-bottom: 30.24px;
            margin-block-end: 30.24px;
            margin-block-start: 37.8px;
          }
        }
    }
}

@media screen and (max-width: 750px) {
    .topTextBody{
        h2{
            text-align: center;
            color: rgb(255, 255, 255);
            font-size: 35px !important;
            font-weight: 900;           
            margin-bottom: 30.24px;
            margin-block-end: 30.24px;
            margin-block-start: 37.8px;
          }
          p{
            text-align: center;
            font-size: 16px !important;
            color: rgb(255, 255, 255);

          }
          h3{
            font-size: 20px !important;
          }
    }
    .abouts1{
      flex-direction: column;
      div{
        width: 90% !important;
      }
      P{
        width: 90% !important;
      }
    }
    .abouts3{
      flex-direction: column;
      padding-top: 30px;
      div{
        width: 90% !important;
      }
      P{
        width: 90% !important;
      }
    }
    .abouts2{
      flex-direction: column-reverse;
      padding-top: 30px;
      div{
        width: 90% !important;
      }
      P{
        width: 90% !important;
      }

    }
  }
 