.apply_body{
    height: 80vh;
    width: 100vw;
    background: linear-gradient(225deg, #F5F7FC 0%, #F5F7FC 100%);
    
}
.tab_pane1{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    .inp_name_body{
        width: 98%;
    }
}
.modal{
    margin-top: 120px;
}
form {
        // display: flex;
        .cancelBtn{
            margin-top: 20px;
            width: 150px;
            height: 40px;
            border-radius: 5px;
            border: none;
            color: white;
            font-size: 15px;
            font-weight: 500;
            transition: all 0.2s ease;
            background-color: #0067F1;
            &:hover {
                background-color: #468ce9;
                cursor: pointer;
            }
        } 
        .addBtn{
            margin-top: 20px;
            width: 150px;
            height: 40px;
            border-radius: 5px;
            border: none;
            background-color: #5aac44;
            color: white;
            font-size: 15px;
            font-weight: 500;
            transition: all 0.2s ease;
            margin-left: 20px;
            &:hover {
                background-color: #72b75f;
                cursor: pointer;
            }

        }

    }

    .inp_name_body {
        padding-top: 10px;
        height: 84px;
        margin-bottom: 24px;
        .require{
            color: red;
            font-size: 13px;
            margin-top: 4px;
        }
        .exam_name {
            // color: #253858;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 5px;

        }

        .inp_body {
            width: 100%;
            height: 55px;
            border: 1px solid rgb(230, 230, 230);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 5px;
            gap: 8px;
            font-size: 20px;
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                /* display: none; <- Crashes Chrome on hover */
                -webkit-appearance: none;
                margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
            }
            
            input[type=number] {
                -moz-appearance:textfield; /* Firefox */
            }

            input {
                width: 100%;
                height: 35px;
                border: none;
                font-size: 18px;
                background-color: transparent;
                &::placeholder {
                    font-size: 16px;
                    font-style: italic;
                    color: #264e93;

                }

                &:focus {
                    outline: none;
                }
            }
        }
    }
    .textArea_name_body {
        padding-top: 10px;
        height: 220px;
        margin-bottom: 30px;
        width: 98%;
        .require{
            color: red;
            font-size: 13px;
            margin-top: 4px;
        }
        .exam_name {
            // color: #253858;
            font-weight: 500;
            font-size: 18px;
            margin-bottom: 5px;

        }

        .inp_body {
            width: 100%;
            height: 200px;
            border: 1px solid rgb(230, 230, 230);
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0px 5px;
            gap: 8px;
            font-size: 20px;
            

            textarea {
                width: 100%;
                height: 100%;
                border: none;
                font-size: 18px;
                background-color: transparent;
                &::placeholder {
                    font-size: 16px;
                    font-style: italic;
                    color: #264e93;

                }

                &:focus {
                    outline: none;
                }
            }
        }
    }
    .about_userBody{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    .inp_name_body{width: 48%;}
    }
.sumbitBtnBody{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.formSumbit{
width: 120px;
height: 40px;
border-radius: 8px;
background: var(--color-one, #1967D2);
color:white;
border: none;
font-size: 18px;
margin-top: 30px;
cursor: pointer;
}
.formSumbitClose{
width: 120px;
height: 40px;
border-radius: 8px;
background: var(--color-one, #aa0f0f);
color:white;
border: none;
font-size: 18px;
margin-top: 30px;
cursor: pointer;
}






@media screen and (max-width: 750px) {
    .tab_pane1{
        
        .inp_name_body{
            width: 100%;
        }
    }
    .about_userBody{
        .inp_name_body{
            width: 100%;
        }
        .textArea_name_body{
            width: 100%;
        }
    }
  }