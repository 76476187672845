.header {
  width: 100%;
  height: auto;
  padding: 30px 0;
  background:linear-gradient(135deg, #1967D2 0%, #4487E2 100%);;
  background-size: cover;
  background: #F4F6FA;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--border-color, #ECEDF2);
  
.header_right{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
  .header_text {
    width: 100%;
    margin: 0 auto;
    // height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .searchPanel{
      width: 50%;
      border-radius: 8px;
      border: 1px solid var(--border-color, #ECEDF2);
      background: #FFF;
      z-index: 2000;
      display: flex;
      align-items: center;
      padding: 0 8px 0 20px;
      .search_input{
        width: 100%;
      // height: 80px;

        display: flex;
        align-items: center;

        // height: 50px;
        padding-left:10px ;
        gap: 10px;
        input{
          width: 100%;
          height: 50px;
          border: none;
          &:focus{
            outline: none;
          }
        }
      }
    }
    h2 {
      color:#202124;
      font-size: 35px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 10px;
    font-style: italic;
    }
     p {
      color: var(--color-two, #fff);
      font-family: Jost;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding-bottom: 20px;
     }
    button {
      width: 150px;
      height: 40px;
      // clip-path: polygon(0% 0%, 75% 0%, 82% 48%, 75% 100%, 0% 100%);
      padding: 7px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.5s ease;
      font-weight: 400;
      color: white;
      font-size: 16px;
      background: #D93025;
      border-radius: 8px;
      margin-left: 10px;
      border: none;
      &:hover {
        cursor: pointer;
        background: #e66157;

      }
    }
  }
}
.whatsapp{
  position: fixed;
  bottom: 4%;
  right: calc(100% - 98%);
  z-index: 888;
  color: #34A853;
  color: #53dc87;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
	animation-name: pulse;
  padding: 10px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease-in;
  animation-timing-function: ease-out;
	animation-iteration-count: infinite;
	animation-duration: 1.5s;

  &:hover{
    bottom: 6%;
  }
}


@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
	}
	80% {
		box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
	}
}
.gridSection{
  background-color: #274f80;
  width: 100%;
.imageGrid{
  // width: 100px !important;
  overflow: hidden;
  img{
    transition: 0.5s all ease-in-out;

  }
  &:hover img{
    cursor: pointer;
    opacity: 0.8;
    transform: scale(1.1);
  }
}
}

.videSection{
  width: 100%;
  // height: 650px;
  .video{
    margin: 0 auto;
    width:100%;
    // height:500px;
    // padding-top:10px;
  }
}
.learning_section {
  width: 100%;
  background-color: #fff;
  padding-top: 100px;
  .contanier {
    max-width: 1200px;
    margin: 0 auto;
    // display: flex;
    padding: 50px 0px;
    flex-wrap: wrap;
    .learning_section_left {
      width: 100%;
      h2 {
        color: var(--color-two, #202124) !important;
        text-align: center !important;
        font-size: 30px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: normal !important;
      }
      p {
        color: var(--text-color, #696969);
        text-align: center;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
    .learning_section_right {
      padding-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      img{
        width: 100px;
        height: 33px;
      }
    }
  }
}
.activity_section {
    width: 100%;
    // background-color: #f2fbfd;
      display: flex;
      justify-content: space-between;
    //   padding: 20px 0px;
      padding-top:30px;
      .activity_section_left {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // margin-bottom: 30px;
        background-color: #274f80;
        padding: 0 20px;
        div{
          max-width: 550px;
          button{
            width: 150px;
            padding: 10px 0;
            border-radius: 8px;
            background: #D93025;
            color: white;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-size: 16px;
            margin-bottom: 30px;
            margin-top: 20px;
            &:hover{
              background: #e66157;
            }
  
          }
        }
        h2 {
          color: white;
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          color: white;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          margin: 10px 0;
        }
      }
      .activity_section_right {
        width: 50%;
        display: flex;
        
        img{
          min-width: 100%;
          min-height: 375px;
        }
      }
   
  }
.activity_section2 {
    width: 100%;
    // background-color: #f2fbfd;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    //   padding: 20px 0px;
    // padding-top: 30px;
      .activity_section_left {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        div{
          max-width: 550px;
          button{
            width: 200px;
            padding: 10px 0;
            border-radius: 8px;
            background: #D93025;
            color: white;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-size: 16px;
            margin-bottom: 30px;
            margin-top: 20px;
            &:hover{
              background: #e66157;
            }
  
          }
        }
        h2 {
          color: var(--color-two, #202124);
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          color: var(--text-color, #696969);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          margin: 10px 0;
        }
      }
      .activity_section_right {
        width: 50%;
        display: flex;
        
        img{
          min-width: 100%;
          max-height: 600px;
        }
      }
   
  }
.activity_section_left_body {
    width: 100%;
    // background-color: #f2fbfd;
      display: flex;
      justify-content: space-between;
      padding-top: 50px;
      .activity_section_left {
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        div{
          width: 500px;
          button{
            width:200px;
            padding: 10px 0;
            border-radius: 8px;
            background: #D93025;
            color: white;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            font-size: 16px;
            margin-top: 20px;
            &:hover{
              background: #e66157;
            }
  
          }
        }
        h2 {
          color: var(--color-two, #202124);
          font-size: 30px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
        p {
          color: var(--text-color, #696969);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 171.429% */
          margin: 10px 0;
        }
      }
      .activity_section_right {
        width: 50%;
        display: flex;
        
        img{
          width: 100%;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          height: 600px;
        }
      }
   
  }
.carusel_body {
  width: 100%;


}

.company_section{
  width: 100%;
  .container{
    width: 1200px;
    margin: 0 auto;
    .companyHeader{
      color: var(--color-two, #202124);
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      padding-top: 50px;
    }
    .companyParagraph{
      color: var(--text-color, #696969);
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .cards_body{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
     
      
      .card{
        width: 45%;
        height: 140px;
        border-radius: 8px;
        border: 1px solid var(--border-color, #ECEDF2);
        background: #FFF;
        display: flex;
        align-items: center;
        padding: 0 10px;
        justify-content: space-between;
        margin-top: 20px;
       
        .card_left{
          display: flex;
          align-items: center;
          gap: 10px;
          img{
            width:100%;
            height: 150px;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
            flex-shrink: 0;
            
          }
          .card_left_text{
              h2{
                color: var(--color-two, #202124);
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                padding-bottom: 7px;
              }
              p{
                color: var(--text-color, #696969);
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                display: flex;
                align-items: center;
                gap: 3px;
              }
          }
        }
        .card_right{
          width: 120px;
          height: 30px;
          color: var(--color-white, #FFF);
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          border-radius: 60px;
          background: var(--color-one, #1967D2);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}


@media screen and (max-width: 1200px) {
  .learning_section {
    .contanier {
       max-width: 850px !important;
      .learning_section_left {
        // width: 290px !important;
        
        p {
          color: rgb(59, 58, 58);
          font-size: 15px;
          line-height: 20px;
        }
      }
      .learning_section_right {
        width: 100%;
      }
    }
  }
  .activity_section {
    .contanier {
      width: 690px !important;
      .activity_section_left {
        width: 290px !important;
        h2 {
          font-size: 20px;
          color: #2babe1;
          letter-spacing: 2px;
          margin-bottom: 40px;
          margin-top: 50px;
        }
        p {
          color: rgb(59, 58, 58);
          font-size: 15px;
          line-height: 20px;
        }
      }
      .activity_section_right {
        width: 490px !important;
      }
    }
  }
}
.jobs_section{
  width: 100%;
  padding-top: 50px;
  display: flex;
  justify-content: center;
  .contanier{
    max-width: 1200px;
    margin: 0 auto;

    h2{
      text-align: center;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      color: var(--color-two, #202124);
    }
    .job_section_headp{
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: var(--text-color, #696969);
      text-align: center;
    }
    .cards_body{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      padding-top: 50px;
      justify-content: center;
      .emptyData_body{
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        // justify-content: center;
        p{
          width: 100%;
          text-align: center;
          font-size: 24px;
          font-weight: 500;
        }
        img{
          width: 300px;
          height: 250px;
        }
      }
      .card{
        width: 280px;
        padding: 0 0 10px 0;
        height: 289px;
        border-radius: 8px;
        border: 1px solid var(--border-color, #ECEDF2);
        background: #FFF;
        display: flex;
        flex-direction: column;justify-content: space-between;
        align-items: center;
        text-align: center;
        transition:all 0.3s ease;
        &:hover{
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
        }
        img{
          width:100%;
          height: 170px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

          // border-radius: 50%;
        }
        .card_companyName{
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: var(--color-four, #34A853);
        }
        .card_jobTitle{
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          color: var(--color-two, #202124);
        }
        .card_jobLocation{
          display: flex;
          align-items: center;
          gap: 5px;
        }
        button{
          width: 150px;
          padding: 10px 0;
          border-radius: 8px;
          background: #D93025;
          color: white;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          font-size: 16px;
          &:hover{
            background: #e66157;
          }

        }
      }
    }
    .loadMore_body{
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 20px;
      button{
        width: 150px;
        padding: 10px 0;
        border-radius: 8px;
        background: #D93025;
        color: white;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-size: 16px;
        &:hover{
          background: #e66157;

        }

      }
    }
  }
}
.select_body{
  max-width: 200px;
  // padding: 0 20px;
  padding-left: calc(100% - 95%);
  // justify-content: center;
  // margin: 0 auto;
  .select_itemBody{
    width: 100%;
    p{
      color: var(--text-color, #3d4ee7);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin: 10px 0;
    }
  }

  .select{
      width: 100%;
      height: 40px;
      border: 1px solid rgb(134, 134, 134);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      select{
          width: 97%;
          height: 37px;
          border: none;
          font-size: 17px;
          background: none;
          &:focus{
            outline: none;
          }
      }
  }
}

@media screen and (max-width: 750px) {
  
 
  .searchPanel{
    width: 85% !important;
    button{
      height: 40px !important;
    }
    .search_input{
    
     
      
    }
  }
  .jobs_section{
    
    .contanier{
      width: 600px !important;
      margin: 0 auto;
    }
  }
  .learning_section {
    .contanier {
      max-width: 600px ;
      flex-direction: column;
      .learning_section_left {
        margin: 0 auto;
        order: 2;
        width: 320px !important;
       
        p {
          text-align: center;
          color: rgb(59, 58, 58);
          font-size: 15px;
          line-height: 20px;
        }
      }
      .learning_section_right {
        margin: 0 auto;
        order: 1;
        width: 320px !important;
        img{
          width: 120px;
          height: 50px;
          margin-bottom: 10px;
        }
      }
    }
  }
  .activity_section_left_body{
    flex-direction: column-reverse;
      align-items: center !important;
      .activity_section_left {
        width: 100% !important;
        h2{
          margin-top: 20px;
        }
        
      }
      .activity_section_right {
        width: 100% !important;

        img{
           width: 90% !important;
          border-radius: none !important;
          // border-bottom-right-radius: 8px;
        }
      }
      
  }
  .activity_section {
    flex-direction: column;
    align-items: center;
   
      .activity_section_left {
        width: 100% !important;
        h2{
          margin-top: 20px;
        }
        
      }
      .activity_section_right {
        width: 100% !important;

        img{
           width: 90% !important;
          border-radius: none;
          // border-bottom-right-radius: 8px;
        }
      }
    }
  .activity_section2 {
    flex-direction: column;
    align-items: center;
   
      .activity_section_left {
        width: 100% !important;
        h2{
          margin-top: 20px;
        }
        
      }
      .activity_section_right {
        width: 100% !important;

        img{
           width: 90% !important;
          border-radius: none;
          // border-bottom-right-radius: 8px;
        }
      }
    }
  }


@media screen and (max-width: 350px) {
  .select_body{
    width: 100% !important;
    justify-content: center !important;
  }
  .learning_section {
    .contanier {
      width: 300px !important;
      .learning_section_left {
        margin: 0 auto;
        width: 300px !important;
       
      }
      .learning_section_right {
        margin: 0 auto;
        width: 300px !important;
      }
    }
  }
  .activity_section {
    .contanier {
      width: 300px !important;
      flex-direction: column;
      .activity_section_left {
        margin: 0 auto;
        width: 300px !important;
      }
      .activity_section_right {
        margin: 0 auto;
        width: 300px !important;
      }
    }
  }
  
}
